import Header from "./Header";
import Baner from "./Baner";
import About from "./About";
import Services from "./Services";
import Footer from "./Footer";

function App() {
  return (
    <div className="bg-black text-white">
      <Header />
      <Baner />
      <About />
      <Services />
      <Footer />
    </div>
  );
}

export default App;
