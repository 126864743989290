function ServiceCard({ service }) {
  return (
    <div className="flex flex-col p-5 border space-y-3">
      <div className="flex items-center mb-4">
        <svg
          width="33"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          <path d={service.svgContent} />
        </svg>
        <span className="text-sm">{service.serviceTitle}</span>
      </div>
      <h6 className="text-lg">{service.serviceDescription}</h6>
      <p className="font-paragraph text-sm">{service.serviceContent}</p>
    </div>
  );
}

export default ServiceCard;
