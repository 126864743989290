import React from "react";
import banerImage from "../images/main.jpeg";

class Baner extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", () => {
      let element = document.getElementById("scrollButton");
      if (window.scrollY > 0) {
        element.classList.add("hidden");
      } else {
        element.classList.remove("hidden");
      }
    });
  }

  scrollToNextSection() {
    window.scrollTo(0, window.outerHeight - 300);
  }

  render() {
    return (
      <div className="grid lg:grid-cols-2" id="home">
        <div className="lg:h-screen">
          <img
            src={banerImage}
            className="w-full h-full object-cover"
            alt="Zamat baner"
          />
        </div>
        <div className="hidden lg:flex flex-col justify-center p-14 xl:p-20 space-y-3">
          <h1 className="text-5xl md:text-7xl xl:text-8xl 2xl:text-9xl font-extrabold uppercase text-center lg:text-left tracking-tighter">
            It is not enough
          </h1>
          <h2 className="text-3xl xl:text-5xl font-bold uppercase text-center lg:text-left tracking-wide">
            for <span className="bg-white text-black p-2">code</span> to work
          </h2>
          <p className="text-center lg:text-left tracking-tighter italic">
            Robert C. Martin, Clean Code: A Handbook of Agile Software
            Craftsmanship
          </p>
        </div>
        <div
          className="absolute w-full bottom-20 cursor-pointer"
          id="scrollButton"
          onClick={() => this.scrollToNextSection()}
        >
          <div className="animate-ping border border-white rounded-full w-20 h-20 flex items-center justify-center mx-auto bottom-0">
            <span className="text-xs">Scroll</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Baner;
