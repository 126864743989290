function About() {
  return (
    <div
      className="grid grid-cols-3 xl:pt-16 xl:pb-16 bg-black-light"
      id="about"
    >
      <div className="p-12 xl:p-16 col-span-3 lg:col-span-1 text-center lg:text-left">
        <h1 className="font-extrabold text-4xl 2xl:text-6xl uppercase bg-white text-black p-4">
          C# .NET 5 Developer
        </h1>
        <h2 className="font-extralight text-3xl">and PHP Developer</h2>
      </div>
      <div className="p-12 xl:p-16 col-span-3 lg:col-span-1 space-y-4">
        <h4 className="font-thin text-5xl uppercase text-center xl:text-justify">
          About me
        </h4>
        <p className="text-center xl:text-justify font-paragraph">
          Hello. My name is Mateusz Zawada. I'm passionate backend developer
          from Poland with over 10 years of experience in C# and php languages
          with strong knowledge about design patterns and architectural
          principles.
        </p>
      </div>
      <div className="p-12 xl:p-16 col-span-3 lg:col-span-1 text-center">
        <a
          href="https://www.linkedin.com/in/matzamat"
          className="relative items-center justify-center px-5 py-3 2xl:px-8 2xl:py-5 border border-white text-base font-medium font-paragraph text-white bg-black hover:bg-white hover:text-black"
        >
          Check my experience
          <span className="animate-ping absolute rounded-full h-3 w-3 bg-white -top-2 -right-1"></span>
        </a>
      </div>
    </div>
  );
}

export default About;
