import React from "react";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentMenu: "#home" };
  }
  componentDidMount() {
    window.addEventListener("scroll", (event) => {
      if (window.scrollY === 0) {
        this.setState({
          currentMenu: "#home",
        });
      }
    });
  }
  active(element) {
    this.setState({
      currentMenu: element.target.hash,
    });
  }
  render() {
    let activeMenuClass =
      "font-paragraph border-b border-white hover:border-black";
    let inactiveMenuClass =
      "font-paragraph border-b border-black hover:border-white";

    return (
      <nav
        className="flex sm:flex flex-col sm:flex-row sm:space-x-6 lg:space-x-12 xl:space-x-20"
        id="menu"
      >
        <a
          href="#home"
          title="Home"
          className={
            this.state.currentMenu === "#home"
              ? activeMenuClass
              : inactiveMenuClass
          }
          onClick={(e) => this.active(e)}
        >
          Home
        </a>
        <a
          href="#about"
          title="About Me"
          className={
            this.state.currentMenu === "#about"
              ? activeMenuClass
              : inactiveMenuClass
          }
          onClick={(e) => this.active(e)}
        >
          About Me
        </a>
        <a
          href="#work"
          title="Work"
          className={
            this.state.currentMenu === "#work"
              ? activeMenuClass
              : inactiveMenuClass
          }
          onClick={(e) => this.active(e)}
        >
          Work &amp; Skills
        </a>
        <a
          href="#contact"
          title="Contact"
          className={
            this.state.currentMenu === "#contact"
              ? activeMenuClass
              : inactiveMenuClass
          }
          onClick={(e) => this.active(e)}
        >
          Contact
        </a>
      </nav>
    );
  }
}

export default Menu;
