function Copyright() {
  return (
    <div className="p-12 bg-black text-white">
      <div className="flex justify-between w-full text-sm">
        <p className="font-paragrapth" >&copy; Copyright 2021 zamat. <span>All rights reserved.</span></p>
        <ul className="hidden lg:flex space-x-5 text-sm">
          <li>
            <a href="#home" className="hover:opacity-50">
              Home
            </a>
          </li>
          <li>
            <a href="#about" className="hover:opacity-25">
              About
            </a>
          </li>
          <li>
            <a href="#work" className=" hover:opacity-25">
              Work &amp; Skills
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Copyright;
