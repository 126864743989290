import ServiceCard from "./ServiceCard";
import services from "../data/services";

function Services() {
  return (
    <div id="work">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-3xl md:mb-12">
          <h2 className="p-3 mb-4 text-4xl font-thin uppercase">
            Work &amp; <span>Skills</span>
          </h2>
          <p className="font-paragraph">
            Each project requires different tools and approaches. On a daily
            basis I work using tools, principles and frameworks such like:
          </p>
        </div>
        <div className="grid gap-4 row-gap-5 sm:grid-cols-2 lg:grid-cols-4">
          {services.map((service) => (
            <ServiceCard service={service} key={service.serviceTitle} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Services;
